import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchTreatmentById } from "../../store/fetchs/treatmentActions";
import "../../assets/styles/User/ShowTreatment/ShowTreatment.css";
import Translation from "../../language.json";
import { LanguageContext } from "./../../LanguageContext";

const ShowTreatment = () => {
  const { language } = useContext(LanguageContext);
  const showTreatmentDictionary = Translation[language].showTreatment;

  const { id } = useParams();
  const dispatch = useDispatch();
  const treatment = useSelector((state) => state.treatment.treatment);
  const [data, setData] = useState({
    patientName: "",
    hospitalName: "",
    categoryName: "",
    serviceNames: "",
    diagnoseNames: "",
    medicineNames: "",
    treatmentForms: [],
  });
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    dispatch(fetchTreatmentById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (treatment) {
      setData({
        patientName: treatment.patientName,
        hospitalName: treatment.hospitalName,
        categoryName: treatment.categoryName || "Qeyd olunayıb",
        serviceNames:
          treatment.services.map((s) => s.name).join(", ") || "Qeyd olunayıb",
        diagnoseNames:
          treatment.diagnoses.map((d) => d.name).join(", ") || "Qeyd olunayıb",
        medicineNames:
          treatment.medicines.map((m) => m.name).join(", ") || "Qeyd olunayıb",
        treatmentForms: treatment.treatmentForms || [],
      });
      setDocuments(treatment.documentUrls);
    }
  }, [treatment]);

  return (
    <div className="show-treatment-container user-box-shadow">
      <h2>{showTreatmentDictionary.header}</h2>
      <div className="show-treatment-content">
        <div className="show-treatment-info-item">
          <TextField
            label={showTreatmentDictionary.patientNameLabel}
            variant="outlined"
            fullWidth
            size="small"
            value={data.patientName}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="show-treatment-info-item">
          <TextField
            label={showTreatmentDictionary.hospitalNameLabel}
            variant="outlined"
            fullWidth
            size="small"
            value={data.hospitalName}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className="show-treatment-info-item">
          <TextField
            label={showTreatmentDictionary.serviceLabel}
            variant="outlined"
            fullWidth
            size="small"
            value={data.serviceNames}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className="show-treatment-info-item">
          <TextField
            label={showTreatmentDictionary.diagnosisLabel}
            variant="outlined"
            fullWidth
            size="small"
            value={data.diagnoseNames}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className="show-treatment-info-item">
          <TextField
            label={showTreatmentDictionary.medicinesLabel}
            variant="outlined"
            fullWidth
            size="small"
            value={data.medicineNames}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className="show-treatment-info-item">
          <TextField
            label={Translation[language].treatmentOptions.categoriesTitle}
            variant="outlined"
            fullWidth
            size="small"
            value={data.categoryName}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        {data.treatmentForms.length > 0 && (
          <div className="show-treatment-info-item">
            <label>{showTreatmentDictionary.treatmentFormsLabel}</label>
            {data.treatmentForms.map((form, index) => (
              <TextField
                key={index}
                label={form.name}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={form.content}
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "16px" }}
              />
            ))}
          </div>
        )}

        {documents &&
          documents.map((url, index) => (
            <div className="show-treatment-info-item">
              <label>{showTreatmentDictionary.documentsLabel}</label>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {showTreatmentDictionary.documentLinkText}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShowTreatment;
