import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PhoneNumber from "../PhoneNumber";
import { LanguageContext } from "../../LanguageContext";
import Translation from "../../language.json";
import { useDispatch, useSelector } from "react-redux";
import {
  createPatientFetch,
  fetchAllPatients,
  fetchPatients,
  updatePatientFetch,
} from "../../store/fetchs/patientActions";
import { toast } from "react-toastify";
import { createPatientFailure } from "../../store/reducers/patientReducer";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useNavigate } from "react-router-dom";
import NewAppointment from "./../AddAppointment/NewAppointment";
import HospitalSelect from "../AddTreatment/HospitalSelect";
import "../../assets/styles/User/AddPatient/AddPatient.css";

const defaultPatient = {
  name: "",
  phone: "",
  birthday: null,
  gender: "",
  insurance: "",
  doctorReferral: "",
};

const AddPatient = ({ patient = defaultPatient, sendedHospital = null }) => {
  const { language } = useContext(LanguageContext);
  const addPatientDictionary = Translation[language].addPatient;

  const [fullName, setFullName] = useState(patient.name);
  const [phone, setPhone] = useState(patient.phone);
  const [selectedHospital, setSelectedHospital] = useState(sendedHospital);
  const [birthday, setBirthday] = useState(patient.birthday);
  const [gender, setGender] = useState(patient.gender);
  const [insurance, setInsurance] = useState(patient.insurance);
  const [doctorReferral, setDoctorReferral] = useState(patient.doctorReferral);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientState = useSelector((state) => state.patient.updateStatus);
  const { loading } = patientState;
  useEffect(() => {
    setFullName(patient.name);
    setPhone(patient.phone);
    setBirthday(dayjs(patient.birthday));
    setGender(patient.gender);
    setInsurance(patient.insurance);
    setDoctorReferral(patient.doctorReferral);
  }, [patient]);

  useEffect(() => {
    if (sendedHospital) setSelectedHospital(sendedHospital);
  }, [sendedHospital]);

  const addPatient = () => {
    if (!fullName) {
      toast.error("Ad soyad tələ olunur");
      return false;
    }
    if (!phone) {
      toast.error("Mobil nömrə tələb olunur");
      return false;
    }
    if (!selectedHospital) {
      toast.error("Xəstəxana tələb olunur");
      return false;
    }
    let patientData = {};
    try {
      birthday.toISOString();
      patientData = {
        fullName,
        phone,
        hospitalId: selectedHospital?.value || 0,
        birthday: birthday?.toISOString(),
        gender: gender === "Male" ? 0 : gender === "Female" ? 1 : 2,
        insurance,
        doctorReferral,
      };
    } catch (error) {
      patientData = {
        fullName,
        phone,
        hospitalId: selectedHospital?.value || 0,
        birthday: null,
        gender: gender === "Male" ? 0 : gender === "Female" ? 1 : 2,
        insurance,
        doctorReferral,
      };
    }

    if (patient.id) {
      console.log(patientData);
      dispatch(updatePatientFetch(patient.id, patientData))
        .then(() => {
          toast.success("Pasiyent yaradıldı");
        })
        .catch((err) => {
          dispatch(createPatientFailure(err.message));
          toast.error(err.message || "Xəta baş verdi");
          return false;
        });
    } else {
      dispatch(createPatientFetch(patientData))
        .then(() => {
          toast.success("Pasiyent yaradıldı");
          dispatch(fetchPatients(1, 10));
        })
        .catch((err) => {
          dispatch(createPatientFailure(err.message));
          toast.error(err.message || "Xəta baş verdi");
          return false;
        });
    }
    return true;
  };

  const handleOpen = () => {
    const result = addPatient();
    result && setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const result = addPatient();
    if (result) {
      await dispatch(fetchAllPatients());
      navigate("/user/patients");
    }
  };
  
  return (
    <div className="add-patient-container user-box-shadow">
      <h2>{addPatientDictionary.personalInfo}</h2>
      <div className="add-patient-form">
        <div>
          <label className="add-patient-form-label">
            {addPatientDictionary.name}
          </label>
          <TextField
            placeholder={addPatientDictionary.name}
            variant="outlined"
            fullWidth
            size="small"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div>
          <PhoneNumber phone={phone} setPhone={setPhone} />
        </div>
        <div>
          <label className="add-patient-form-label">
            {addPatientDictionary.hospital}
          </label>
          <HospitalSelect
            selectedHospital={selectedHospital}
            setSelectedHospital={setSelectedHospital}
          />
        </div>
        <div>
          <label className="add-patient-form-label">
            {addPatientDictionary.birthday}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem>
              <DatePicker
                fullWidth
                value={dayjs(birthday).isValid() ? birthday : null}
                onChange={(newValue) => setBirthday(newValue)}
                maxDate={dayjs()}
              />
            </DemoItem>
          </LocalizationProvider>
        </div>
        <div>
          <label className="add-patient-form-label">
            {addPatientDictionary.gender}
          </label>
          <FormControl variant="outlined" size="small" fullWidth>
            {!gender && (
              <InputLabel shrink={false} id="gender-label">
                Select
              </InputLabel>
            )}
            <Select
              labelId="gender-label"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value="Male">{addPatientDictionary.male}</MenuItem>
              <MenuItem value="Female">{addPatientDictionary.female}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <label className="add-patient-form-label">
            {addPatientDictionary.insurance}
          </label>
          <TextField
            placeholder={addPatientDictionary.insurance}
            variant="outlined"
            fullWidth
            size="small"
            value={insurance}
            onChange={(e) => setInsurance(e.target.value)}
          />
        </div>
        <div>
          <label className="add-patient-form-label">
            {addPatientDictionary.doctorReferral}
          </label>
          <TextField
            placeholder={addPatientDictionary.doctorReferral}
            variant="outlined"
            fullWidth
            size="small"
            value={doctorReferral}
            onChange={(e) => setDoctorReferral(e.target.value)}
          />
        </div>
      </div>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          style={{
            color: "var(--app-main-color)",
            borderColor: "var(--app-main-color)",
            marginRight: "10px",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {addPatientDictionary.save}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="app-theme-color"
          onClick={handleOpen}
        >
          {addPatientDictionary.makeAppointment}
        </Button>
      </Box>
      <NewAppointment open={open} handleClose={handleClose} />
    </div>
  );
};

export default AddPatient;
