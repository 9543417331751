import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitalAppointmentsPrice } from "../../store/fetchs/statisticsActions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Pagination,
} from "@mui/material";
import "../../assets/styles/User/Statistic/StatisticsHospitalAppointment.css";

const StatisticsHospitalAppointment = ({ dateRange }) => {
  const dispatch = useDispatch();
  const hospitalAppointmentsPrice = useSelector(
    (state) => state.statistics.hospitalAppointmentsPrice
  );
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10); // You can change the page size as needed

  useEffect(() => {
    if (dateRange) {
      dispatch(fetchHospitalAppointmentsPrice({ ...dateRange, page, pageSize }));
    }
  }, [dispatch, dateRange, page, pageSize]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="statistics-hospital-appointment-container">
      <h4 className="statistics-hospital-appointment-header">Pasiyentlər</h4>
      <TableContainer>
        <Table aria-label="appointments table">
          <TableHead>
            <TableRow>
              <TableCell className="user-table-header">#</TableCell>
              <TableCell className="user-table-header">Pasiyentin Adı</TableCell>
              <TableCell className="user-table-header">Qiymət</TableCell>
              <TableCell className="user-table-header">Qalıq Qiymət</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hospitalAppointmentsPrice.items.length > 0 ? (
              hospitalAppointmentsPrice.items.map((appointment, index) => (
                <TableRow key={index} className="appointment-statistic">
                  <TableCell>{index + 1 + (page - 1) * pageSize}</TableCell>
                  <TableCell>{appointment.patientName}</TableCell>
                  <TableCell>{appointment.price}</TableCell>
                  <TableCell>{appointment.doctorPrice || 0}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data available for the selected date range.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" className="pagination-container">
        <Pagination
          count={hospitalAppointmentsPrice.meta.totalPages || 1}
          page={page}
          onChange={handlePageChange}
          className="app-theme-color container-pagination-color"
        />
      </Box>
    </div>
  );
};

export default StatisticsHospitalAppointment;
