import React, { useState, useEffect, useContext } from "react";
import { Button, Box, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { fetchTreatmentForms } from "../../store/fetchs/treatmentFormActions";
import { createTreatmentFetch } from "../../store/fetchs/treatmentActions";
import PatientSelect from "../PatientSelect";
import ServiceProcedureSelect from "./ServiceProcedureSelect";
import DiagnoseSelect from "./DiagnoseSelect";
import MedicineSelect from "./MedicineSelect";
import UploadFile from "../UploadFile";
import NewAppointment from "./../AddAppointment/NewAppointment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Translation from "../../language.json";
import { useLoading } from "../../LoadingContext";
import { LanguageContext } from "../../LanguageContext";
import { createTreatmentFailure } from "../../store/reducers/treatmentReducer";
import HospitalSelect from "./HospitalSelect";
import CategorySelect from "./CategorySelect";
import "../../assets/styles/User/AddTreatment/AddTreatment.css";

const AddTreatment = () => {
  const { language } = useContext(LanguageContext);
  const addTreatmentDictionary = Translation[language].addTreatment;

  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const treatmentForms = useSelector(
    (state) => state.treatmentForm.treatmentForms
  );
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [formContents, setFormContents] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTreatmentForms());
  }, [dispatch]);

  const handleFormContentChange = (id, content) => {
    setFormContents({
      ...formContents,
      [id]: content,
    });
  };

  const saveTreatment = () => {
    if (!selectedPatient) {
      toast.error(addTreatmentDictionary.errors.patientRequired);
      return false;
    }
    if (!selectedHospital) {
      toast.error(addTreatmentDictionary.errors.hospitalRequired);
      return false;
    }
    if (selectedServices.length === 0) {
      toast.error(addTreatmentDictionary.errors.serviceRequired);
      return false;
    }
    if (selectedDiagnoses.length === 0) {
      toast.error(addTreatmentDictionary.errors.diagnosisRequired);
      return false;
    }
    if (selectedMedicines.length === 0) {
      toast.error(addTreatmentDictionary.errors.medicineRequired);
      return false;
    }
    if (!selectedCategory) {
      toast.error(addTreatmentDictionary.errors.categoryRequired);
      return false;
    }

    const treatmentFormsData = Object.keys(formContents).map((id) => ({
      treatmentFormId: parseInt(id),
      content: formContents[id],
    }));

    const treatmentData = {
      patientId: selectedPatient?.value || 0,
      hospitalId: selectedHospital?.value || 0,
      servicesId: selectedServices.map((service) => service.value),
      categoryId: selectedCategory?.value || 0,
      diagnoseIds: selectedDiagnoses.map((d) => d.value),
      medicineIds: selectedMedicines.map((m) => m.value),
      treatmentForms: treatmentFormsData,
      documentUrls: imageUrls,
    };
    setLoading(true);
    dispatch(createTreatmentFetch(treatmentData))
      .then(() => {
        toast.success("Müalicə yaradıldı");
        setLoading(false);
      })
      .catch((err) => {
        dispatch(createTreatmentFailure(err.message));
        toast.error("Xəta baş verdi,yenidən cəhd edin");
        setLoading(false);
        return false;
      });

    return true;
  };

  const handleSave = () => {
    const result = saveTreatment();
    result && navigate("/user/treatments");
  };

  const handleOpen = () => {
    const result = saveTreatment();
    result && setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDeleteFile = (url) => {
    setImageUrls((prevUrls) => prevUrls.filter((item) => item !== url));
  };

  return (
    <div className="add-treatment-container user-box-shadow">
      <h2>{addTreatmentDictionary.header}</h2>

      <div className="add-treatment-form">
        <div className="add-treatment-top">
          <div>
            <label>{addTreatmentDictionary.patientLabel}</label>
            <PatientSelect
              selectedPatient={selectedPatient}
              setSelectedPatient={setSelectedPatient}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.hospitalLabel}</label>
            <HospitalSelect
              selectedHospital={selectedHospital}
              setSelectedHospital={setSelectedHospital}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.serviceLabel}</label>
            <ServiceProcedureSelect
              selectedService={selectedServices}
              setSelectedService={setSelectedServices}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.diagnosisLabel}</label>
            <DiagnoseSelect
              selectedDiagnoses={selectedDiagnoses}
              setSelectedDiagnoses={setSelectedDiagnoses}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.medicinesLabel}</label>
            <MedicineSelect
              selectedMedicines={selectedMedicines}
              setSelectedMedicines={setSelectedMedicines}
            />
          </div>
          <div>
            <label>
              {Translation[language].treatmentOptions.categoriesTitle}
            </label>
            <CategorySelect
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        </div>
        <div className="add-treatment-examination-questionnaire">
          <h3>{addTreatmentDictionary.examinationQuestionnaire}</h3>
          {treatmentForms.map((form) => (
            <div key={form.id}>
              <label>{form.name}</label>
              <textarea
                placeholder={form.name}
                value={formContents[form.id] || ""}
                onChange={(e) =>
                  handleFormContentChange(form.id, e.target.value)
                }
              />
            </div>
          ))}
        </div>
        <div className="add-treatment-document-container">
          <label>{addTreatmentDictionary.documentsLabel}</label>
          <UploadFile
            setImageUrls={setImageUrls}
            content={addTreatmentDictionary.uploadDocument}
            className="add-treatment-document-container-upload-button"
            multiple={true}
          />
          {imageUrls.length > 0 && (
            <div className="uploaded-file-links">
              {imageUrls.map((url, index) => (
                <div key={index} className="uploaded-file-link">
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    View Document {index + 1}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteFile(url)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Box
        display="flex"
        justifyContent="flex-end"
        mt={2}
        style={{ marginBottom: 10 }}
      >
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          style={{
            color: "var(--app-main-color)",
            borderColor: "var(--app-main-color)",
            marginRight: "10px",
          }}
          onClick={handleSave}
        >
          {addTreatmentDictionary.saveButton}
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "var(--app-main-color)",
            borderColor: "var(--app-main-color)",
            marginRight: "10px",
          }}
          onClick={handleOpen}
        >
          {addTreatmentDictionary.nextCheckupButton}
        </Button>
      </Box>
      <NewAppointment
        handleClose={handleClose}
        open={open}
        selectedPatient={selectedPatient}
      />
    </div>
  );
};

export default AddTreatment;
