import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import HospitalSelect from "../AddTreatment/HospitalSelect";
import Translation from "../../language.json";
import { LanguageContext } from "../../LanguageContext";
import "../../assets/styles/User/Treatment/FilterDrawer.css";

const AppointmentFilterDrawer = ({ open, handleClose, applyFilter }) => {
  const { language } = useContext(LanguageContext);
  const filterDrawerDictionary = Translation[language].filterDrawer;

  const [selectedHospital, setSelectedHospital] = useState(null);
  const [status, setStatus] = useState("");
  const [payment, setPayment] = useState(null);

  const handleFilter = () => {
    applyFilter({
      hospital: selectedHospital,
      status,
      payment: payment !== null ? parseInt(payment, 10) : null,
    });
    handleClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div className="filter-drawer-modal">
        <h2>{filterDrawerDictionary.header}</h2>
        <FormControl fullWidth size="small">
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="">Hamısı</MenuItem>
            <MenuItem value="Pending">Gözlənilir</MenuItem>
            <MenuItem value="Finished">Bitmiş</MenuItem>
            <MenuItem value="Cancelled">Ləğv Edilmiş</MenuItem>
          </Select>
        </FormControl>
        <Box my={2}>
          <HospitalSelect
            selectedHospital={selectedHospital}
            setSelectedHospital={setSelectedHospital}
          />
        </Box>
        <FormControl fullWidth size="small">
          <InputLabel id="payment-select-label">Ödəniş</InputLabel>
          <Select
            labelId="payment-select-label"
            label="Ödəniş"
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
          >
            <MenuItem value="">Hamısı</MenuItem>
            <MenuItem value="0">Kartla</MenuItem>
            <MenuItem value="1">Nağd</MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="contained"
            className="app-theme-color"
            onClick={handleFilter}
          >
            {filterDrawerDictionary.applyFilterButton}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ color: "var(--app-main-color)" }}
          >
            {filterDrawerDictionary.cancelButton}
          </Button>
        </Box>
      </div>
    </Drawer>
  );
};

export default AppointmentFilterDrawer;
